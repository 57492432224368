
import './App.css';

const images = require.context('./img', true);
const imageList = images.keys().map(image => images(image));




function App() {
  return (
    <div className="App bg-gray-200">

      {/* <Header /> */}

      <div className='w-full h-44 bg-[#faebd7] md:flex hidden justify-evenly'>
        <div className='flex justify-center items-center'>
          <img src="https://i.postimg.cc/fW1RFLWC/snehadeepam-logo-footer.png" alt="Logo" />
        </div>

        <div className='flex justify-center items-center gap-3'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-10 text-[#485e97]">
              <path fill-rule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd" />
            </svg>

          </div>
          <div className='flex items-start text-left flex-col'>
            <h1 className='font-bold'>
              Our School
            </h1>
            <p>
              W5RR+7M4,
              <br /> Alamelumangapuram,<br />

              Vellore- 632009
            </p>
          </div>
        </div>

        <div className='flex justify-center items-center gap-3'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-10 text-[#485e97]">
              <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
              <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
            </svg>


          </div>
          <div className='flex items-start text-left flex-col'>
            <h1 className='font-bold'>
              Email us
            </h1>
            <p>
              foyervellore@yahoo.co.in <br />
              elizabethpuliyal@gmail.com

            </p>
          </div>
        </div>

        <div className='flex justify-center items-center gap-3'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-10 text-[#485e97]">
              <path d="M21.721 12.752a9.711 9.711 0 0 0-.945-5.003 12.754 12.754 0 0 1-4.339 2.708 18.991 18.991 0 0 1-.214 4.772 17.165 17.165 0 0 0 5.498-2.477ZM14.634 15.55a17.324 17.324 0 0 0 .332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 0 0 .332 4.647 17.385 17.385 0 0 0 5.268 0ZM9.772 17.119a18.963 18.963 0 0 0 4.456 0A17.182 17.182 0 0 1 12 21.724a17.18 17.18 0 0 1-2.228-4.605ZM7.777 15.23a18.87 18.87 0 0 1-.214-4.774 12.753 12.753 0 0 1-4.34-2.708 9.711 9.711 0 0 0-.944 5.004 17.165 17.165 0 0 0 5.498 2.477ZM21.356 14.752a9.765 9.765 0 0 1-7.478 6.817 18.64 18.64 0 0 0 1.988-4.718 18.627 18.627 0 0 0 5.49-2.098ZM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 0 0 1.988 4.718 9.765 9.765 0 0 1-7.478-6.816ZM13.878 2.43a9.755 9.755 0 0 1 6.116 3.986 11.267 11.267 0 0 1-3.746 2.504 18.63 18.63 0 0 0-2.37-6.49ZM12 2.276a17.152 17.152 0 0 1 2.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0 1 12 2.276ZM10.122 2.43a18.629 18.629 0 0 0-2.37 6.49 11.266 11.266 0 0 1-3.746-2.504 9.754 9.754 0 0 1 6.116-3.985Z" />
            </svg>


          </div>
          <div className='flex items-start text-left flex-col'>
            <h1 className='font-bold'>
              Website
            </h1>
            <p>
              snehadeepam.org

            </p>
          </div>
        </div>

        <div className='flex justify-center items-center gap-3'>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-10 text-[#485e97]">
              <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
            </svg>


          </div>
          <div className='flex items-start text-left flex-col'>
            <h1 className='font-bold'>
              Call us
            </h1>
            <p>
              +91 98438 98986 <br />
              +91 98430 09522
            </p>
          </div>
        </div>

      </div>



      <div className='w-full h-14 bg-white flex justify-between px-2 items-center sticky top-0'>
        <div>
          <a href="https://snehadeepam.org/">
            <div className='bg-sky-200 shadow flex justify-center items-center border hover:border-black p-2 rounded-full top-5 left-5 sticky h-12'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-8 text-[#485e97]">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
              </svg>
            </div>
          </a>
        </div>
        <div className='md:flex hidden gap-5 text-black font-semibold'>
          <a href="https://snehadeepam.org/"><div> Home </div> </a>
          <a href="https://snehadeepam.org/#about"><div> About</div> </a>
          <a href="https://snehadeepam.org/#academics"> <div> Academics </div>  </a>
          <a href="https://snehadeepam.org/#wealth"> <div> Wealth</div>  </a>
          <a href="https://snehadeepam.org/#p"> <div> Principal</div>  </a>
          <a href="https://snehadeepam.org/#contact"> <div> Concact</div>  </a>
          <a href=""> <div> Gallery</div>  </a>
        </div>


        <div>
         <a href="https://docs.google.com/forms/d/e/1FAIpQLSf_eDcnkmbAW4YXhf2af1VipHF3iPtQErqX1VY9dfv9taGXEw/viewform?usp=send_form">
          <div className='bg-violet-700 py-2 px-2 flex justify-center items-center rounded-xl text-white'>
            Admissions open now
          </div>
          </a>
        </div>
      
      </div>

      <div>
        <h1 class="text-6xl mt-5 font-bold py-2 text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-red-600" id='gallery-font'>
          Gallery
        </h1>
      </div>

      <div className='flex flex-wrap w-full p-[2%] justify-center gap-5'>

        {imageList.map((image, index) => (
          <><img className="shadow-md" key={index} src={image} alt={`image-${index}`} width={400} height={800} />
            <p>{image.default}</p>
          </>
        ))}

      </div>
    </div>
  );
}

export default App;
